import './App.scss';
import React, {useRef, useEffect} from "react";
import Scrollbar from 'smooth-scrollbar';
import gsap from 'gsap';

function App() {
    let scrollbar = useRef(null);
    let timeline = useRef(null);

    let maxScroll;

    let m = {};
    m.direction = 'forward';
    m.progress = 0;

    useEffect(() => {
        initItems();
        // eslint-disable-next-line
    }, []);

    const initItems = () => {
        gsap.set('.Slide-01', {x: 0});
        gsap.set('.label-bottom', {x: 300});
        gsap.set('.label-top', {x: -300});
        gsap.set('.label', {alpha: 0, rotateX: 360});
        gsap.set('.what', {alpha: 0, x: 30});
        gsap.set('.arrow', {alpha: 0});

        gsap.to('.arrow', {yoyo: true, repeat: -1, y: -10, duration: 1})

        gsap.to('.label-bottom', {duration: 1, x: 0, ease: "expo"});
        gsap.to('.label-top', {duration: 1, x: 0, ease: "expo"});
        gsap.to('.label', {duration: .25, alpha: 1, ease: "linear"});
        gsap.to('.label', {duration: 1, rotateX: 0, ease: "expo", delay: 1});
        gsap.to('.what', {alpha: 1, duration: 1, x: 0, ease: "expo", delay: 1.5, onComplete: initStart});
        gsap.to('.arrow', {alpha: 1, duration: 1, ease: "linear", delay: 2});
    }

    const initStart = () => {
        gsap.set('.label-top', {height: "100%"});

        gsap.set('.label-bottom', {color: "#979797"});
        gsap.set('.label-bottom', {top: "100%", height: 0});
        gsap.set('.label-bottom-inner', {yPercent: -100});

        initScroll();
    }

    const initScroll = () => {
        scrollbar.current = Scrollbar.init(document.querySelector('.container'), {});

        scrollbar.current.addListener(function (status) {
            const progress = status.offset.y / maxScroll;
            timeline.current.progress(progress);

            m.direction = 'forward';
            if (progress < m.progress) {
                m.direction = 'reverse';
            }
            m.progress = progress;

            gsap.set('.Slides', {y: status.offset.y});

            gsap.to('.arrow', {alpha: 0, duration: .25, ease: "linear"});
            if(m.arrowDelay) {
                m.arrowDelay.kill();
            }
            m.arrowDelay = gsap.delayedCall(5, () => gsap.to('.arrow', {alpha: 1, duration: 1, ease: "linear"}))
        });

        slide01.init();
    }

    const slide01 = {
        init: function () {
            let h = 4.35 * window.innerHeight;
            gsap.set('.height-container', {height: h});
            maxScroll = h - window.innerHeight;
            slide01.maxScroll = maxScroll;

            timeline.current = gsap.timeline();
            timeline.current.pause();

            timeline.current.addLabel('slide01')
                .to('.label-bottom', {duration:.25, color: "#979797", top: "0%", height: "100%"}, 'slide01')
                .to('.label-bottom-inner', {duration:.25, yPercent: 0}, 'slide01')

                .addLabel('slide02')
                .set('.Slide-01', {y: 0, x: 0})
                .set('.Slide-02', {y: window.innerHeight, x: 0})
                .to('.Slide-01',{duration: 1, y: -window.innerHeight, ease: 'power1.in'}, 'slide02')
                .to('.Slide-02', {duration: 1, y: 0, ease: 'power1'}, 'slide02')

                .addLabel('slide03')
                .set('.Slide-02', {y: 0, x: 0})
                .set('.Slide-03', {y: window.innerHeight, x: 0})
                .to('.Slide-02',{duration: 1, y: -window.innerHeight, ease: 'power1.in'}, 'slide03')
                .to('.Slide-03', {duration: 1, y: 0, ease: 'power1'}, 'slide03')

                .addLabel('slide04')
                .set('.Slide-03', {y: 0, x: 0})
                .set('.Slide-04', {y: window.innerHeight, x: 0})
                .to('.Slide-03',{duration: 1, y: -window.innerHeight, ease: 'power1.in'}, 'slide04')
                .to('.Slide-04', {duration: 1, y: 0, ease: 'power1'}, 'slide04')
                .to('.arrow', {duration: .1, fill: '#ffffff'})
        }
    }

    return (
        <>
            <div className="container">
                <div className="height-container"></div>
                <div className="Slides">
                    <div className="Slide Slide-01">
                        <div className="label">
                            <div className="label-spacer label-flexbox">
                                <div className="label-item">Mick&nbsp;</div>
                                <div className="label-item">Berberich</div>
                            </div>
                            <div className="label-top label-flexbox">
                                <div className="label-item">Mick&nbsp;</div>
                                <div className="label-item">Berberich</div>
                            </div>
                            <div className="label-bottom">
                                <div className="label-bottom-inner label-flexbox">
                                    <div className="label-item">Mick&nbsp;</div>
                                    <div className="label-item">Berberich</div>
                                </div>
                            </div>
                        </div>
                        <div className="what">full stack development/consulting</div>
                    </div>
                    <div className="Slide Slide-02">
                        <div className="mail">
                            <a href="mailto:hello@mickberberich.de" target="_blank" rel="noreferrer noopener">hello@mickberberich.de</a>
                        </div>
                    </div>
                    <div className="Slide Slide-03">
                        <div className="legal">
                            <h5>Impressum</h5>
                            <p>
                                Michael Berberich<br/>
                                Pferchstr. 9<br/>
                                67735 Mehlbach
                            </p>
                        </div>
                    </div>
                    <div className="Slide Slide-04">
                        <div className="legal">
                            <h5>Datenschutz</h5>
                            <p>
                                Keine Cookies, kein Tracking.<br/>
                                Verantwortlich für den Datenschutz: Michael Berberich<br/><br/>

                                Die üblichen Server-Logfiles werden aus Sicherheitsgründen mit folgenden Daten erstellt:<br/>
                                IP-Adresse, Browser, Betriebssystem, Referrer
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="arrow">
                <svg viewBox="0 0 19.96 10.75">
                    <path d="M19.21,0c0.2,0,0.39,0.08,0.53,0.22c0.29,0.29,0.29,0.77,0,1.06l-9.25,9.25
	c-0.29,0.29-0.77,0.29-1.06,0L0.18,1.28C-0.06,1-0.06,0.58,0.18,0.3c0.27-0.31,0.74-0.35,1.06-0.08l8.72,8.72l8.72-8.72
	C18.82,0.08,19.01,0,19.21,0"/>
                </svg>
            </div>
        </>
    );
}

export default App;
